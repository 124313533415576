export const linksFilters = [
    {
        id: 'ALL', title: 'Mostrar todos', icon: 'MailIcon', params: { },
    },
    {
        id: 'erp', title: 'Propuestas de ventas', icon: 'MailIcon', params: { },
    },
    {
        id: 'study', title: 'Generación de estudios', icon: 'MailIcon', params: { },
    },
]

export const links = [
    {
        id: 'ieco', type: ['erp', 'study'], title: 'IECO', subtitle: 'La plataforma de diseño para instalaciones fotovoltaicas. Da vida a tus proyectos de autoconsumo con simulaciones precisas, propuestas comerciales y estudios de ingeniería.', url: 'https://ieco.io/', urlLogin: 'https://ieco.io/login', logo: 'https://ieco.io/img/logo+name.9d443f0a.svg', images: [], params: { }, active: true,
    },
    {
        id: 'solar-monkey', type: ['erp'], title: 'Solar Monkey', subtitle: 'Solar Monkey es el software para hacer estudios y propuestas de fácil uso, preciso, rápido y profesional. Usando Solar Monkey, lograrás vender más en mucho menos tiempo', url: 'https://solarmonkey.es/', urlLogin: 'https://app.solarmonkey.nl/accounts/login/', logo: 'https://solarmonkey.es/wp-content/uploads/2022/04/Logo-Webp-180.webp', images: [], params: { }, active: true,
    },
    {
        id: 'Suntropy', type: ['erp'], title: 'Suntropy', subtitle: 'Suntropy es un software de autoconsumo solar pensado para instaladores que te permite realizar estudios de autoconsumo en 10 minutos, con un aspecto totalmente personalizado. Desde la captación de la oportunidad a través de la calculadora solar SolarForm, hasta el envío online del presupuesto y firma digital de contratos.', url: 'https://app.suntropy.es', urlLogin: 'https://app.suntropy.es/signup?utm_source=refereal&utm_id=evorgy_browser&utm_medium=refereal&utm_campaign=partners', logo: 'https://documents.enerlence.com/f/dcc05b895e70481db127/?dl=1', images: [], params: { }, active: true,
    },
]

export const getLinkById = id => {
    const res = links.filter(val => val.id === id)
    return res[0]
}

export const prettyUrl = value => value.replace(/ /g, '-').replace(/ñ/g, 'n').replace(/[^a-zA-Z0-9-]/, '').toLowerCase()
export const copyPixCodeClick = value => {
    navigator.clipboard.writeText(value)
}
