<template>
  <div class="min-vh-100">
    <b-tabs
        pills
        card
        vertical
        class="tab-charts min-vh-100"
    >
      <b-tab
          v-for="(filter, index) in linksFilters"
          :key="index"
          :title="filter.title"
      >
        <b-row>
          <b-col class="pt-2 pb-2">
            <h4 class="m-b">{{ $t('EVO Store') }}</h4>
            <!-- Searchbar -->
            <div class="d-flex align-content-center justify-content-between w-100 mt-1">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                      icon="SearchIcon"
                      class="text-muted"
                  />
                </b-input-group-prepend>
                <b-form-input
                    type="search"
                    v-model="search"
                    placeholder="Search Chart"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
        <!-- Chart List -->
        <b-row>
          <b-col
              v-for="link in linksByFilter(filter)"
              :key="link.id"
              sm="12"
              class="todo-item"
          >
            <b-card
                class="parent-chart"
            >
              <img v-if="link.logo" :src="link.logo" class="link-logo mb-1"/>
              <h3>{{ link.title}}</h3>
              <p>{{ link.subtitle}}</p>
              <div>
                <b-button
                    v-if="link.url"
                    variant="primary"
                    class="center"
                    :href="link.url"
                    target="_blank"
                >
                  <feather-icon icon="ExternalLinkIcon" class="mr-1"/>
                  <span>{{ $t('Go to website') }}</span>
                </b-button>
                <b-button
                    v-if="link.urlLogin"
                    variant="outline-primary"
                    class="center ml-2"
                    :href="link.urlLogin"
                    target="_blank"
                >
                  <feather-icon icon="ExternalLinkIcon" class="mr-1"/>
                  <feather-icon icon="UsersIcon"/>
                  <span>{{ $t('Login portal') }}</span>
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BInputGroup, BInputGroupPrepend, BTab, BTabs,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  ref, watch, computed, onUnmounted, watchEffect, onMounted,
} from '@vue/composition-api'
import _ from 'lodash'
import store from '@/store'
import { links, linksFilters, getLinkById } from "@/views/apps/evostore/evostoreOptions"

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BTab,
    BTabs,

    vSelect,
  },
  setup() {
    const search = ref('')
    const linksFiltered = ref(links)

    watch(search, () => {
      if (search.value) {
        linksFiltered.value = links.filter(c => _.includes(_.toLower(c.id), _.toLower(search.value)))
      } else {
        linksFiltered.value = links
      }
    })

    const linksByFilter = filter => linksFiltered.value.filter(c => (filter.id === 'ALL' || c.type.includes(filter.id)))

    return {
      linksByFilter,
      linksFiltered,
      linksFilters,
      search,
    }
  },
}
</script>

<style lang="scss" scoped>
.link-logo{
  max-width: 150px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
